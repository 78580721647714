.avatar-container figure {
    float: left;
    /*margin: 10px;*/
    margin-bottom: 0;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    background-color: #dadada;
    text-align: center;
    line-height: 73px;
}
.avatar-container .avatar1 {
    border-radius: 50%!important;
    height: 80px!important;
    width: 80px!important;
    padding: 0;
    -o-object-fit: cover;
    object-fit: cover;
    overflow: hidden;
}
#uploadAvartar {
    display: none;
}
#process-container {
    background-color: #f5f5f5;
    border: 1px solid #dcdcdc;
    font-weight: 700;
    overflow-y: hidden;
    padding: 4px 4px 4px 8px;
    max-width: 100%;
    position: relative;
    line-height: 21px;
    margin-bottom: 5px;
    margin-top: 5px;
}
#process-container .filename {
    font-size: 13px;
    font-weight: 600;
    max-width: 220px;
    overflow: hidden;
    white-space: nowrap;
    float: left;
    margin-right: 10px;
    color: #15c;
}
#process-container .remove {
    cursor: pointer;
    height: 16px;
    margin-top: 1px;
    opacity: .5;
    width: 16px;
    position: absolute;
    right: 0;
    top: 5px;
}