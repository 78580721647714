
body {
    font-size: 13px;
	color: #333;
    font-family: Arial, Verdana, Helvetica, Sans-Serif;
	font-family: 'Merriweather'!important;
    margin: 0px!important;
    padding: 0px!important;
}
.head-page {
    position: fixed;
    z-index: 1000;
	display: contents;
}
.menu-top-fix {
    background-color: #1f595d;
    float: left;
    width: 100%;
    top: 0;
    z-index: 99;
    padding-top: 5px;
	height:60px;
	height:112px;
}
.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.new-logo-top{
	border-bottom: 1px solid #fff;
    padding-bottom: 5px;
	margin-bottom: 2px;
}
.member-avatar figure{
	float: left;
    margin: 10px 3px;
    margin: 5px 3px;
    margin-bottom: 0;
    height: 30px;
    width: 30px;
	height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #fff;
    text-align: center;
    line-height: 26px;
}
.member-avatar figure>img{
	width: 100%;
    height: 100%;
    border-radius: 50%;
}
.md-form .form-control {
    margin: 0 0 .5rem 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    padding: .3rem 0 .55rem 0;
    background-image: none;
    background-color: transparent;
    height: auto;
}
.md-form input[type=date], .md-form input[type=datetime-local], .md-form input[type=email], .md-form input[type=number], .md-form input[type=password], .md-form input[type=search-md], .md-form input[type=search], .md-form input[type=tel], .md-form input[type=text], .md-form input[type=time], .md-form input[type=url], .md-form textarea.md-textarea {
    -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    border-bottom: 1px solid #ced4da;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    background-color: transparent;
}
*, ::after, ::before {
    box-sizing: border-box;
}
.fa, .fab, .fal, .far, .fas {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}
.md-form .fa, .md-form .fas{
    /*font-weight: 400;*/
	    display: block;
    position: absolute;
    right: 1%;
    right: 0%;
    float: right;
    cursor: pointer;
    top: 5px;
    color: #fff;
    font-size: 20px;
	cursor:pointer;
}
.md-form .form-control{
	/*line-height: 30px;*/
    border-color: #fff;
    color: #fff;
    text-align: center;
	cursor:pointer;
}
.pd0{
	padding:0px!important;
}
.lng select{
	background: #1f595d;
    color: #fff!important;
    padding: 0;
    border: 0;
    background-color: #1f595d!important;
	outline:none!important;
}
.md-form .form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 1; /* Firefox */
}

.md-form .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #fff;
}

.md-form .form-control::-ms-input-placeholder { /* Microsoft Edge */
  color: #fff;
}
.mgt7{
	margin-top:7px!important;
}
.dropbtn {
	background-color: #1f595d;
	color: white;
	/* padding: 16px; */
	font-size: 20px;
	font-size: 18px;
	border: none;
	cursor: pointer;
	line-height: 30px;
	text-transform: uppercase;
	padding-top: 10px;
}

.dropbtn:hover, .dropbtn:focus {
  background-color: #1f595d;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  background-color: #fff;
  min-width: 175px;
  min-width: 100%;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius:0px;
}

.dropdown-content a {
  color: black;
  /*padding: 5px 10px;*/
  text-decoration: none;
  display: block;
  color: #36322f;
  color: #fff;
  border-bottom: 1px solid #868686;
  text-align:left;
  font-size: 14px;
  /*font-weight: 600;*/
  text-transform: uppercase;
}

.dropdown a:hover {background-color: #ddd; color: #36322f;}
.show {display: block;right: 0;}
a:not([href]):not([tabindex]) {
     color: #36322f;
    text-decoration: none;
}
.member-avatar .dropdown-content{
	min-width:70px!important;
	min-width:100px!important;
	top: 47px;
    left: 10px;
}
.box-login{
	margin:30px;
}
.scrollere {
    min-height: 50px!important;
    height: inherit!important;
    max-height: 265px!important;
    overflow-y: auto!important;
    overflow-x: hidden;
	padding: 10px;
}
.dropdown-content .lg{
    padding-left: 10px;
    vertical-align: middle;
}
.scrollere::-webkit-scrollbar {
    width: 3px;
    background-color: rgba(27,25,25,.84);
    border-radius: 10px;
}
.scrollere::-webkit-scrollbar-thumb {
    background-color: #fff;
    background-image: -webkit-gradient(linear,40% 0,75% 84%,from(#ccc),color-stop(.6,#ccc),to(#ccc));
    border-radius: 10px;
}
.scrollere::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fafafa;
}
.dropdown-content  header {
    border-radius: 4px 4px 0 0;
    background-color: #a6dcdc;
    text-align: center;
    padding: 10px;
    position: relative;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    color: #36322f;
}
.dropdown-content  header h4{
	font-size:16px;
	margin-bottom: 0;
}
.hamburger{
	width:40%;
	position: absolute;
    padding-left: 50%;
    padding-left: 59%;
}
.navbar-toggler-icon{
	width: 1.2em;
    height: 1em
}
.navbar-light .navbar-toggler {
    color: #FFF;
    border: 0; 
    background: #fff;
    padding: 0;
}
.bg-light {
     background-color: #1f595d!important;
}
.drdw{
	width: 60%;
    /*text-align: right;*/
    text-align: center;
}
.navbar-light .navbar-toggler-icon, .navbar-light .navbar-toggler-icon:after, .navbar-light .navbar-toggler-icon:before {
    background: #fefefe;
    content: "";
    display: block;
    height: 2px;
    position: absolute;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    transition: top .1s ease-in .15s,bottom .1s ease-in .15s,-webkit-transform .1s ease-in;
    transition: transform .1s ease-in,top .1s ease-in .15s,bottom .1s ease-in .15s;
    transition: transform .1s ease-in,top .1s ease-in .15s,bottom .1s ease-in .15s,-webkit-transform .1s ease-in;
    width: 1.25rem;
}
.navbar-light .navbar-toggler-icon {
    top: 1.6875rem;
    transition: background-color 10ms ease-in .1s;
}
.navbar-light .navbar-toggler-icon:before {
    top: -.5rem;
}
.navbar-light .navbar-toggler-icon:after {
    bottom: -.5rem;
}
.navbar-light .navbar-toggler-icon {
    top: 22px;
    transition: background-color 10ms ease-in .1s;
}
.mobileMenu-toggle, .product, .productMasonry, .youtube {
    overflow: hidden;
}
.mobileMenu-toggle {
    height: 3rem;
    height: 40px;
    width: 3.4375rem;
    display: inline-block;
    font-size: 0;
    left: 0;
    padding: 0 1.125rem;
    position: relative;
    z-index: 500;
	padding: 0 5px;
}
.mobileMenu-toggle .mobileMenu-toggleIcon {
    top: 1.6875rem;
    transition: background-color 10ms ease-in .1s;
}
.mobileMenu-toggle .mobileMenu-toggleIcon, .mobileMenu-toggle .mobileMenu-toggleIcon:after, .mobileMenu-toggle .mobileMenu-toggleIcon:before {
    background: #fefefe;
    content: "";
    display: block;
    height: 2px;
    position: absolute;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    transition: top .1s ease-in .15s,bottom .1s ease-in .15s,-webkit-transform .1s ease-in;
    transition: transform .1s ease-in,top .1s ease-in .15s,bottom .1s ease-in .15s;
    transition: transform .1s ease-in,top .1s ease-in .15s,bottom .1s ease-in .15s,-webkit-transform .1s ease-in;
    width: 1.25rem;
}
.mobileMenu-toggle .mobileMenu-toggleIcon:before {
    top: -.5rem;
}
.mobileMenu-toggle .mobileMenu-toggleIcon:after {
    bottom: -.5rem;
}
.mobileMenu-toggle .mobileMenu-toggleIcon {
    top: 22px;
    transition: background-color 10ms ease-in .1s;
}
#mymenu{
    right: 10px;
    right: 0px;
    left: 0px;
    position: fixed;
	top: 60px;
}
.mobileMenu-toggle:hover {
    background-color: #1f595d!important;
    color: #ffffff;
}
.dropdown a:hover {
    background-color: unset;
}
#mymenu a{
	border-bottom:0;
}
.rigister-title{
	font-weight:600;
	display:inline;
	vertical-align: bottom;
	margin-left:10px;
	text-transform: uppercase;
}
.list-group-item{
	background-color: #048e83;
	color: #fff!important;
	padding: .35rem 1.25rem;
	margin-bottom: 1px;
}
.myDropdown  a {
    color: black;
    padding: 5px 10px;
    text-decoration: none;
    display: block;
    color: #36322f;
    color: #fff;
    border-bottom: 1px solid #868686;
    text-align: left;
    font-size: 13px;
    font-weight: 600;
}
.lngDropdow .dropdown-content a {
    color: black;
    padding: 5px 10px;
    text-decoration: none;
    display: block;
    color: #36322f;
    border-bottom: 1px solid #868686;
    text-align: left;
    font-size: inherit; 
    font-weight: normal; 
}
.lngDropdow .dropdown-content {
    min-width: 175px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 5px;
}
/*.txt-search{
	color: transparent!important;
    text-shadow: 0 0 0 #fff!important;
}*/
div.bet-title {
    padding: 10px;
    background: #04978b;
    color: #fff;
    cursor: pointer;
    border-bottom: 1px solid #fff;
    border-radius: 5px 5px 0 0;
}
div.bet-title a {
    color: #fff;
    font-weight: 500;
}
.bet-title .div-month, .bet-title .div-quarter, .bet-title .div-year {
    display: inline-block;
}
:hover, a {
    text-decoration: none!important;
}
.rdt {
    position: relative;
}
.bet-title input {
    width: 60px!important;
    text-align: center;
    padding: 3px;
    height: 25px;
	color: #04978b!important;
    border-radius: 5px;
}
.rdtOpen .rdtPicker {
    display: block;
}
.rdtPicker {
    display: none;
    position: absolute;
    width: 250px;
    width: 160px;
    padding: 4px;
    margin-top: 1px;
    z-index: 99999!important;
    background: #fff;
    -webkit-box-shadow: 0 1px 3px rgba(0,0,0,.1);
    box-shadow: 0 1px 3px rgba(0,0,0,.1);
    border: 1px solid #f9f9f9;
}
.rdtPicker th.rdtNext, .rdtPicker th.rdtPrev, .rdtPicker th.rdtSwitch, td.rdtMonth, td.rdtYear {
    color: #000;
}
.rdtPicker th.rdtNext, .rdtPicker th.rdtPrev {
    font-size: 21px;
    vertical-align: top;
}
.rdtPicker th {
    border-bottom: 1px solid #f9f9f9;
}
.rdtPicker td, .rdtPicker th {
    text-align: center;
    height: 28px;
}
.rdtPicker th.rdtSwitch {
    width: 100px;
}
.rdtPicker th {
    border-bottom: 1px solid #f9f9f9;
}
.rdtPicker table {
    width: 100%;
    margin: 0;
}
td.rdtMonth, td.rdtYear {
    height: 50px;
    width: 25%;
    cursor: pointer;
}
i.collapse, i.expand {
    width: 14px;
    cursor: pointer;
    display: inherit;
    float: right;
}
i.collapse {
    background-image: url(https://www.coopbet.com/media/collapse.png);
}
i.expand {
    background-image: url(https://www.coopbet.com/media/expand.png);
}
.profile-box .data {
    background-color: #fff;
    border: 1px solid #ddd!important;
    border-top: none!important;
    margin: 0!important;
}
.btt1 {
    border-top: 1px solid #ddd;
    padding: 5px 0!important;
}
.profile-box span.label {
    font-weight: 100;
    width: 120px;
    float: left;
    font-size: 1em;
    display: block;
    color: #000;
	text-align: center;
}
.pd10_5{
	padding-top:10px;
	padding-bottom:10px;
	padding-left:5px;
	padding-right:5px;
	
}
.div-quarter .rdtPicker{
	right:0px!important;
}
.profile-box span.draw, .profile-box span.lost, .profile-box span.won {
    width: 50px;
    display: inline-block;
    color: #fff;
    /*padding: 4px 0;*/
    border-radius: 10px;
}
span.won {
    background-color: #e20000;
}
/*.txt-search:focus {
    color: #ffffff!important;
}
.txt-search:before{
	color:red;
}*/
input.search-value::-webkit-calendar-picker-indicator {
  display: none;
}
input.search-value{
	margin-left:0px!important;
}
/*=========== muc ruoi==========*/
.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    width: 82%;
    width: 70%;
	max-width: 50px;
    height: 25px;
    margin: 0;
    margin-left: 5px;
    vertical-align: top;
}
.switch .slider{
    max-height: 360px;
    overflow: hidden;
    min-height: 27px;
    width: 50px;
    width: 98%;
}
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
.switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
}
.switch input:checked+.slider {
    background-color: #00fcfd;
}
.switch .slider.round {
    border-radius: 34px;
}
.switch input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}
.switch .slider.round:before {
    border-radius: 50%;
}
.switch .slider:before {
    position: absolute;
    content: "";
    height: 23px;
    width: 23px;
    left: 1px;
    left: 0px;
    bottom: 2px;
    background-color: #1f595d;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
}
.switch input:checked+.slider:before{
	left: -8px;
}
/*=========== end ==============*/
.pdtl10{
	padding-top:12px!important;
}
.icon-view-search{
	color: #fff;
    font-size: 22px;
    padding-top: 5px;
}
.lb-r{
	width: 40%;
    margin-left: 3%;
    text-align: right;
}
.lb-l{
	width: 45%;
}
.lb-l1{
	width:10%;
	color:#fff;
	font-size:18px;
}
.lb-r1{
	width: 90%;
}
.fa-bell{
	font-size: 1.7em; cursor: pointer; color: #fff; padding: 10px; border: none;
}
.txtr{
	text-align:right;
}
.header-notification{
	font-size: 13px;
    font-weight: 700;
    margin: 0;
    /* border-bottom: 1px solid #ddd; */
    width: 100%;
    background-color: #fff;
    height: 30px;
    line-height: 30px;
}
.rtool {
    text-align: right;
    line-height: 30px;
}
.rtool a {
    font-size: 13px;
    font-weight: 400;
    color: #337ab7;
}
.brd1px {
    border-bottom: 1px solid #ddd;
}
#notifi-content .scroll-nt {
    min-height: 50px!important;
    height: inherit!important;
    max-height: 480px!important;
    overflow-y: auto!important;
    overflow-x: hidden;
	width: 100%;
}
.scroll-nt::-webkit-scrollbar {
    width: 6px;
    background-color: rgba(27,25,25,.84);
    border-radius: 10px;
}
::-webkit-scrollbar {
    width: 12px;
}
.scroll-nt::-webkit-scrollbar-thumb {
    background-color: #fff;
    background-image: -webkit-gradient(linear,40% 0,75% 84%,from(#ccc),color-stop(.6,#adb5bd),to(#ccc));
    border-radius: 10px;
}
.scroll-nt::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fafafa;
}
#notifi-content .ft-items .ctn .ft-item {
    padding: 5px 0;
}
.ft-items .ctn .ft-item {
    float: left;
    width: 100%;
    border-bottom: 1px solid #ddd;
    padding: 5px 0;
}
.ctn .ft-item figure {
    width: 50px;
    height: 50px;
	 width: 40px;
    height: 40px;
	vertical-align: middle;
    margin: 0 10px;
}
#profile-friend .ctn .ft-item figure {
    width: 50px;
    height: 50px;
}
.ct-user-nf {
    text-align: left;
    padding-top: 7px;
	margin: 0 10px;
}
.img-ntf {
    border-radius: 50%;
    border: 1px solid #f0f0f0;
	width: 100%;
}
.name-user {
    font-weight: 600;
    margin-right: 5px;
}
.time-ntf {
    padding-left: 7px;
    color: #b3b3b3;
}
.badge-notification{
	font-family: Open Sans,sans-serif;
    position: absolute;
    top: 3px;
    right: 20px;
    font-weight: 300;
    padding: 3px 6px;
    background-color: #f36a5a;
    color: #fff;
    border-radius: 10px;
}
.albt{
	vertical-align:bottom;
}
.mrgt5px{
	margin-top:5px!important;
}
.fr-it-body .add-friend {
    background-color: #04978b!important;
    border: 1px solid #fff!important;
    color: #fff!important;
}
.mrgr3 {
    margin-right: 3px;
}
.btn-a {
    background-color: #f5f6f7;
    color: #1f595d;
    font-weight: 600!important;
}
.icon-friend {
    font-size: 18px;
    color: #adb3bc;
    vertical-align: top;
}
.icon-friend, .pdr5 {
    padding-right: 5px;
}
.txt-red {
    color: #c90a0a;
}
.left-btn{
	float: left;
    background-color: #f5f6f7;
}
.right-btn{
	float: right;
    background-color: #f5f6f7;
    /* border-left: 1px solid #ccc; */
    margin-left: 1px;
    width: 49%;
}
.pdlr16px{
	padding-left:16px;
	padding-right:16px;
	margin-top: 5px;
}
#fr-body{
	margin-top:5px!important;
}
.hamburger #mymenu{
	width:96%;
	width:99%;
	width:100%;
	min-width:inherit;
}
.txt-gr{
	color: rgb(119, 119, 119);
}
.input-contact {
    border: 0;
    border-bottom: 1px solid #aeaeae;
    border-radius: 0;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
    padding-bottom: 0;
}
.lbl-contact {
    line-height: 30px;
	margin:0px;
	font-weight: 700;
}
input.btn-sigin {
    border: solid 1px #dddddd;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    text-transform: uppercase;
    font-size: 10px;
    padding: 5px 15px;
    background-color: #04978b;
}
.v-more {
    color: #00f!important;
}
.ad-img-o-tip{
	margin:15px 0!important;
	margin:0!important;
	text-align:center;
	border: 1px solid #ddd;
    padding: 10px;
    height: 73px;
}
.tbl-tipters>thead>tr>td, .tbl-tipters>thead>tr>th{
	background-color: #eee;
    border: 0!important;
	padding: 12px 8px;
}
.tbl-tipters>tbody>tr>td{
	/*border-top: 0!important;
	border-bottom: 1px solid #ddd;*/
	    vertical-align: middle;
}
.tbl-tipters .img-tipster{
	width:50px;
	height:50px;
	border-radius:50%;
	border:1px solid #ddd;
}
.tbl-tipters .name-tip{
	font-size:14px;
}
.tbl-tipters .verifi-tip{
	font-size: 12px;
    color: #777;
}
.pd6_0{
	padding: 6px 0px!important;
}
#tab-bankroll .pagination {
    margin: 10px 0!important;
}
.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
    z-index: 3;
    color: #fff;
    cursor: default;
    background-color: #eeffee;
    border-color: #addead;
    color: #fe4064;
    font-weight: 900;
}
.pagination>li:first-child>a, .pagination>li:first-child>span {
    margin-left: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.pagination>.disabled>a, .pagination>.disabled>a:focus, .pagination>.disabled>a:hover, .pagination>.disabled>span, .pagination>.disabled>span:focus, .pagination>.disabled>span:hover {
    color: #777;
    cursor: not-allowed;
    background-color: #fff;
    border-color: #ddd;
}
.pagination>li>a, .pagination>li>span {
    color: #1f595d;
}
.tab-rank li>a {
    text-transform: capitalize!important;
}
.pagination>li>a, .pagination>li>span {
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: #337ab7;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #ddd;
}
.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
    z-index: 3;
    color: #fff;
    cursor: default;
    background-color: #efe;
    border-color: #addead;
    color: #fe4064;
    font-weight: 900;
}
.pagination>li>a, .pagination>li>span {
    color: #1f595d;
}
.rank-mth input{
	width: 85px!important;
    text-align: center;
	height: 32px;
    font-size: 13px;
}
.rank-mth .react-datepicker__month .react-datepicker__month-text, .rank-mth .react-datepicker__month .react-datepicker__quarter-text {
    display: inline-block;
    width: 50px;
    margin: 5px;
}
.rank-mth{
	padding: 10px;
    text-align: left;
}
.icon-caret{
	position: absolute;
    top: 17px;
    right: 5px;
}
.txt-f-t{
	text-align: center;
    border: 1px solid #ddd;
    padding: 10px 0;
    font-weight: 600;
    background-color: #f6f6f6;
    background-color: #eee;
    font-size: 15px
}
.react-datepicker-popper[data-placement^=top] {
    margin-bottom: 10px;
}
.react-datepicker-popper {
    z-index: 1;
	/*    display: contents;*/
}
.react-datepicker {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: .8rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #aeaeae;
    border-radius: .3rem;
    display: inline-block;
    position: relative;
}
.react-datepicker__navigation--previous {
    left: 10px;
    border-right-color: #4a9095!important;
}
.react-datepicker__navigation {
    background: none;
    line-height: 1.7rem;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 10px;
    width: 0;
    padding: 0;
    border: .45rem solid transparent;
    z-index: 1;
    height: 10px;
    width: 10px;
    text-indent: -999em;
    overflow: hidden;
}
.react-datepicker__navigation--next {
    right: 10px;
    border-left-color: #4a9095!important;
}
.react-datepicker-time__header, .react-datepicker-year-header, .react-datepicker__current-month {
    margin-top: 0;
    color: #000;
    font-weight: 700;
    font-size: .944rem;
}
.react-datepicker__header {
    text-align: center;
    background-color: #f0f0f0;
    border-bottom: 1px solid #aeaeae;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;
    padding-top: 8px;
    position: relative;
}
.react-datepicker__month {
    margin: .4rem;
    text-align: center;
}
.react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle:before, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow:before, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow:before, .react-datepicker__year-read-view--down-arrow, .react-datepicker__year-read-view--down-arrow:before {
    border-bottom: none;
    border-top-color: #fff;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__year-read-view--down-arrow {
    bottom: 0;
    margin-bottom: -8px;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle:before, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow:before, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow:before, .react-datepicker__year-read-view--down-arrow, .react-datepicker__year-read-view--down-arrow:before {
    box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    height: 0;
    width: 1px;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__year-read-view--down-arrow {
    margin-left: -8px;
    position: absolute;
}
.react-datepicker-time__header, .react-datepicker-year-header, .react-datepicker__current-month {
    margin-top: 0;
    color: #000;
    font-weight: 700;
    font-size: .944rem;
}
.react-datepicker__header {
    text-align: center;
    background-color: #f0f0f0;
    border-bottom: 1px solid #aeaeae;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;
    padding-top: 8px;
    position: relative;
}
.rank-mth .react-datepicker__month .react-datepicker__month-text, .rank-mth .react-datepicker__month .react-datepicker__quarter-text {
    display: inline-block;
    width: 30px;
    margin: 5px;
}
.react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__quarter-text.react-datepicker__month--in-range:hover, .react-datepicker__quarter-text.react-datepicker__month--selected:hover, .react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover, .react-datepicker__quarter-text.react-datepicker__quarter--selected:hover {
    background-color: #216ba5;
}
.react-datepicker__month--in-range:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--selected:hover, .react-datepicker__quarter--in-range:hover, .react-datepicker__quarter--in-selecting-range:hover, .react-datepicker__quarter--selected:hover {
    background-color: #1d5d90;
}
.react-datepicker__month--selected{
	 border-radius: .3rem;
    background-color: #216ba5;
    color: #fff;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle:before, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow:before, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow:before, .react-datepicker__year-read-view--down-arrow, .react-datepicker__year-read-view--down-arrow:before {
    border-bottom: none;
    border-top-color: #fff;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__year-read-view--down-arrow {
    bottom: 0;
    margin-bottom: -8px;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle:before, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow:before, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow:before, .react-datepicker__year-read-view--down-arrow, .react-datepicker__year-read-view--down-arrow:before {
    box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    height: 0;
    width: 1px;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__year-read-view--down-arrow {
    margin-left: -8px;
    position: absolute;
}
.react-datepicker-popper[data-placement^=bottom] {
    margin-top: 10px;
    margin-top: 0px;
}
.icon-carets {
    position: absolute;
    top: 17px;
    left: 83px;
}
.react-datepicker__triangle, 
.react-datepicker__triangle:before,
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle,
 .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle:before,
 .react-datepicker__month-read-view--down-arrow,
 .react-datepicker__month-read-view--down-arrow:before,
 .react-datepicker__month-year-read-view--down-arrow, 
 .react-datepicker__month-year-read-view--down-arrow:before, 
 .react-datepicker__year-read-view--down-arrow,
 .react-datepicker__year-read-view--down-arrow:before {
    box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    height: 0;
    width: 1px;
}

#root>div{
	overflow: hidden;
}

.scrol-menu{
   /* max-height: inherit!important;*/
}
#div-tips .nav-tabs .nav-link{
	background-color: #fff!important;
    padding: 6px 25px;
    border: 1px solid #ddd;
    font-weight: 600;
    text-transform: uppercase!important;
    border-radius: 0;
    margin: 1px;
    margin-top: 0;
}
#div-tips .nav-tabs  .nav-link.active {
    background-color: #ddd!important;
    border: 0;
	color: #c90a0a!important;
    cursor: default;
}
#price_mth {
    width: 35px;
    height: 20px;
    vertical-align: top;
    margin: 1px;
    border-radius: 0;
    text-align: center;
    padding: 0px;
    display: inline-block;
    font-size: 13px;
	margin-top:-1px;
}
.btn-save-tp{
	border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    color: #fff;
    text-transform: uppercase;
    background-color: #04978b!important;
	font-size: 11px!important;
    font-weight: 600;
    padding: 2px 6px!important;
    margin-right: 1px;
}
#div-tips{
	margin-top: -30px;
    width: 100%;
    display: inline-block;
}
#div-tips .nav-tabs {
    border-bottom: 0px solid #dee2e6;
}
#div-tips .tab-content{
	margin-top: 7px;
    border-top: 4px solid #ddd;
	padding: 7px 0;
	min-height: 150px;
}
#div-tips .table td,#div-tips .table th {
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    border: 1px solid #ddd;
	vertical-align: middle;
}
#div-tips .table td{
	padding:5px;
}
.btn-approve{
	border: 1px solid #ddd;
    border-radius: 5px;
    color: #fff;
    font-size: 12px;
    background-color: #04978b;
	padding:3px 6px;
}
.fit-idex{
	z-index:1;
}
.date-tips .react-datepicker-popper{
	right: 15px;
	z-index:2;
}
.lin-in{
	color:blue;
	text-decoration: underline;
}
.txt-al-ct{
	text-align:center;
}
.inv-title {
    font-size: 24px;
    font-weight: 600;
}
.inv-no {
    font-weight: 600;
    font-size: 13px;
}
.line-1px {
    border-bottom: 1px solid #ddd;
    margin: 15px;
}
.txt-b {
    font-weight: 500;
    font-size: 16px;
    text-align:center;
}
.txt-ct-ti{
	margin-top:15px;
}
.txt-tk-l,.txt-tk-r {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
}
.txt-time-see-tip {
    margin-top: 10px;
    font-size: 15px;
    font-weight: 600;
}
 .pttt {
    line-height: 32px;
    font-weight: 600;
}
.copi-stk {
    padding-top: 5px;
    padding-left: 15px;
}
.copied {
    float: right;
    font-size: 14px;
    cursor: pointer;
}
.tbl-tipters>tbody>tr>td {
    vertical-align: middle;
    padding: 5px;
}
.new-logo-top-none {
    border-bottom: 1px solid #04dbdb36;
    padding-bottom: 5px;
    margin-bottom: 2px;
}
.linklogo{
	text-align: right;
    margin: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
@media (min-width: 576px){
	.container {
		max-width: 100%;
	}
}
@media (min-width: 700px){
	.hamburger #mymenu {
		width:98%;
	}
	.lbl-contact{
		line-height: 40px;
	}
}