.box-login {
    border-radius: 5px;
    padding-bottom: 10px;
    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
}
.box-login h1 {
    margin: 0;
    text-transform: uppercase;
    font-size: 17px;
    text-align: center;
    border-radius: 5px 5px 0 0;
    background-color: #04978b;
    font-weight: 700;
    padding: 17px 0;
    color: #fff;
    position: relative;
}
.box-login .content {
    padding: 50px 10px;
    padding: 40px 10px;
    background-color: #fff;
	    display: flow-root;
}
.box-login .content .input-group {
    margin-bottom: 10px;
}
.box-login  .input-group-addon,.box-login .input-group-btn {
    width: 13%;
    white-space: nowrap;
    vertical-align: middle;
}
.box-login .input-group-addon {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px;
	    border-right: 0;
}
.box-login input{
	/*width:87%;*/
}
.box-login .input-group-addon i{
	padding-top:5px;
}
.box-login .content #signin_remember {
    margin-left: 0!important;
}
.box-login .content .checkbox span {
    display: inline-block;
    padding-left: 20px;
    vertical-align: middle;
}
#btn_login{
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    color: #fff;
    text-transform: uppercase;
    font-size: 10px;
    padding: 10px 15px;
    background-color: #04978b;
	/*font-weight: 900;*/
    font-size: 14px!important;
}
#btn_login:hover, .login-facebook:hover, .login-google:hover, .login-partial a:hover {
    color: #ff0!important;
    opacity: .8;
}
.box-login .content .btn-sm {
    text-align: center!important;
    width: 100%;
    padding: 7px 0;
    font-size: 12px!important;
    border-radius: 5px!important;
}
.externall-login .item-ex {
    float: left;
    width: 100%;
    margin: 10px 0 0;
}
.externall-login .item-ex a {
    color: #fff;
    cursor: pointer;
    width: 100%;
    border-radius: 5px;
    display: inline-block;
}
.externall-login .item-ex a.login-google {
    background: #e04a39;
}
.externall-login .item-ex a.login-google {
    background: #e04a39;
    color: #fff;
}
.externall-login .item-ex a .fa {
    display: inline-block;
    border-right: 1px solid #000;
    padding: 10px 0;
    width: 30px;
    text-align: center;
    margin-right: 10px;
	font-size: 18px;
    font-weight: 900;
}
.login-google button {
    display: inline-block!important;
    background: #e04a39!important;
    color: #fff!important;
    width: 190px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 2px;
    border: none;
    font-size: 14px!important;
    font-family: inherit!important;
    text-align: left;
    padding: 0!important;
    outline: none;
}
.kep-login-facebook, .login-google button {
    font-weight: 400!important;
    text-transform: inherit!important;
	    border: 0px!important;
    box-shadow: none!important;
}
.login-google>button>div{
	display:none;
}
.dsp-block{
	display:block;
}
.box-login .content .checkbox span {
    margin-top: -5px;
}
.login-error{
	color:red;
	padding:15px 0px;
}
#btn_sign {
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    color: #fff;
    text-transform: uppercase;
    font-size: 10px;
    padding: 10px 15px;
    background-color: #04978b;
    /* font-weight: 900; */
    font-size: 14px!important;
	margin-top: 10px;
}
.portlet {
    margin-top: 0;
    margin-bottom: 25px;
    padding: 0;
}

.portlet.box {
    padding: 0!important;
}
.portlet.box.green {
    border: 1px solid #39aeb5;
    border-top: 0;
}
.portlet>.portlet-title {
    min-height: 41px;
}
.portlet.box.green>.portlet-title{
    background-color: #17a2b8;
}
.portlet.box>.portlet-title {
    border-bottom: 0;
    padding: 0 10px;
    margin-bottom: 0;
    color: #fff;
}
.portlet.box>.portlet-title>.caption {
    padding: 11px 0 9px;
    font-weight: 600;
}
.portlet>.portlet-title>.caption {
    float: left;
    display: inline-block;
    font-size: 14px;
    line-height: 18px;
    font-weight: 300;
    padding: 10px 0;
}
.email-div, .mabm-div, .newpass-div{
	margin-top:15px;
}
.portlet.box>.portlet-body {
    background-color: #fff!important;
    padding: 20px 0px;
    min-height: 30px;
}
.portlet>.portlet-body {
    clear: both;
}
.mgbt10 {
    margin-bottom: 10px;
}

.btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}
.btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
}
.mgl5 {
    margin-left: 5px;
}
.btn-info.active.focus, .btn-info.active:focus, .btn-info.active:hover, .btn-info:active.focus, .btn-info:active:focus, .btn-info:active:hover, .open>.dropdown-toggle.btn-info.focus, .open>.dropdown-toggle.btn-info:focus, .open>.dropdown-toggle.btn-info:hover {
    color: #fff;
    background-color: #269abc;
    border-color: #1b6d85;
}
.btn-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}
.error1, .error2 {
    color: red;
}
.txt-sm-fg{
	font-size: 12px;
    font-style: italic;
    color: #555;
}
.pdr0{
	padding-right:0px;
}
.link {
    color: #1736dc;
    text-decoration: underline!important;
}
.ct-us{
	font-size:14px;
	text-decoration: underline!important;
}
#btn_contact {
    border: 1px solid #adb5bd;
    border-radius: 5px;
    cursor: pointer;
    color: #495057;
    text-transform: uppercase;
    font-size: 10px;
    padding: 10px 15px;
    background-color: #eee;
    /* font-weight: 900; */
    font-size: 14px!important;
    margin-top: 10px;

}