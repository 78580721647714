.pd0{
	padding:0px;
}
.randomad{
	border: 1px solid #dad7d7;
    margin-left: 1px;
    margin-right: 1px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 1px;
}
.addTip{
	margin-left:0;
	margin-right:0;
	padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
}
.txt-r{
	text-align:right;
	padding-top: 3px;
}
.btn-create-tip{
	border-radius: 10px;
    display: inline-block;
    padding: 3px 10px;
    background-color: #04978b;
    color: #fff;
}
.line-ct{
	/*border:2px solid #dad7d7;*/
	border-top:4px solid #dad7d7;
	margin-left:0;
	margin-right:0;
}
.title-user-info figure {
    float: left;
    margin: 5px;
    margin-bottom: 0;
    height: 65px;
    width: 65px;
    border-radius: 50%;
}
.title-user-info figure img{
	border-radius:50%!important;
	border: 1px solid #9E9E9E;
}
.title-user-info{
	margin-left:0;
	margin-right:0;
	padding-top:10px;
	padding-bottom:10px;
}
.title-user-info .name{
	font-size: 16px;
    font-weight: 600;
	margin-top: 10px;
}
.txtR{
	text-align:right;
}
.timetip{
	padding-right: 5px!important;
    vertical-align: bottom;
    /*padding-top: 34px!important;*/
	color: hsla(0,0%,7%,.6);
    font-style: italic;
}
.linect{
	border-bottom:1px solid #dad7d7;
	margin-left:0;
	margin-right:0;
}
.tipct{
	margin-left:0;
	margin-right:0;
	padding-bottom:10px;
	padding-top:10px;
	display: block;
}
.tip-name{
	text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: #1f595d;
	padding-left:0;
	padding-right:0;
}
.btn-gr{
	color:#fff!important;
	background-color: #04978b;
	padding:5px 5px;
	margin-left:10px;
	width: 40px;
    border: 0;
}
.btn-ora{
	color:#fff!important;
	background-color: #FF9800;
	padding:5px 5px;
	margin-left:5px;
	min-width: 40px;
    border: 0;
}
.tiso{
	padding-top:5px;
	text-align:center;
	padding-left:0;
	padding-right:0;
}
.mg0{
	margin-left:0;
	margin-right:0;
}
span.status {
    color: #fff;
    border-radius: 15px;
    display: inline-block;
    padding: 3px 10px 1px;
    padding: 5px 5px;
    text-transform: uppercase;
    font-weight: 600;
    width: 60px;
    width: auto;
    margin-left: 5px;
    min-width: 56px;
    text-align: center
}
span.won {
    background-color: #e20000;
}
.txt-ct{
	text-align: center;
}
.time-tip{
	text-align:center;
	margin-top:10px;
	padding-left:0;
	padding-right:0;
	margin-bottom:2px;
}
.txt-tm-tp{
	padding-left: 10px;
    text-transform: uppercase;
    font-weight: 500;
}
.descp-tp{
	margin-left: 5px;
    margin-right: 5px;
	padding:10px 5px;
	padding-top:0;
}
.commet-tp .row{
	margin-left:0px;
	margin-right:0px;
	padding:10px 5px;
}
.list-tp{
	margin-left: 10px;
    margin-right: 10px;
    border: 1px solid #ddd;
    margin-bottom: 1px;
	border-radius: 5px;
}
.rw-like{
	padding:0;
}
a.like-bet {
    /*background: url(https://www.coopbet.com/media/likeicon.png) no-repeat top 2px left 15px;*/
    float: left;
    height: 20px;
    font-size: 13px;
    line-height: 27px;
    text-align: left;
    padding-left: 39px;
    padding-left: 5px;
    cursor: pointer;
    margin-top: -4px;
}
.num-like{
	padding-left:5px;
	color:#333;
}
.fl-r{
	text-align:right;
}
 .img-icon-sm {
    margin-right: 16px;
    background-color: transparent;
    overflow: hidden;
}
.img-icon-sm {
    border-radius: 50%!important;border: 1px solid #9E9E9E;
}
.img-icon-sm{
    display: block;
    max-height: var(--yt-img-max-height,none);
    max-width: var(--yt-img-max-width,100%);
    border-radius: var(--yt-img-border-radius,none);
}
.time-cmt {
    color: hsla(176,7%,58%,.86);
    color: #606060;
    font-size: 1.2rem;
    font-size: 13px;
	margin-left: 7px;
	font-weight:normal;
}
.name-cmt, .name-comment, .view-more {
    color: #0d9488!important;
}

.name-cmt {
    font-size: 1.4rem;
    font-size: 14px;
    font-weight: 600!important;
    line-height: .8rem;
}
.img-icon-dislike, .img-icon-like {
    cursor: pointer;
}

.img-icon-dislike, .img-icon-like {
    width: 18px;
    height: 18px;
}
.img-icon-dislike, .img-icon-like {
    cursor: pointer;
}
.img-icon-dislike {
    margin-left: 20px;
}
.img-icon-dislike, .img-icon-like {
    width: 18px;
    height: 18px;
}
.reply-cmt {
    margin-left: 15px;
    color: #11111199;
    font-size: 1.3rem;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: .007px;
    text-transform: uppercase;
    padding-top: 3px;
    /*position: absolute;*/
}
.lh50{
	height:50px;
	background: #f7f6f6fa;
}
.btn-cmt{
	position: absolute;
    right: 10px;
    margin-top: 10px;
    margin-top: 15px;
    height: 30px;
    background: #04978b;
    border: 0;
    color: #fff;
}
span.lost {
    background-color: #0f3234;
}
span.pending {
    background-color: #797979;
}
span.draw {
    background-color: #04988b;
}
.txtct{
	text-align:center;
}
.area-cmt{
	padding-right:35px!important;
	padding-right:18%!important;
	font-size: 12px;
    padding-left: 3px;
	line-height: 1;
	min-height: 38px;
	border-radius:20px;
}
.post-reply{
	border-radius:5px;
	margin-top: 4px;
	right: 4px;
}
.t-reply{
	padding-top:10px!important;
}
.count-like {
    padding: 5px;
    padding-top: 3px;
    position: absolute;
}
.img-icon-sm24 {
    width: 24px!important;
    height: 24px!important;
    margin-right: 16px;
    -ms-flex: none;
    flex: none;
    border-radius: 50%;
    background-color: transparent;
    overflow: hidden;
    position: relative;
    margin-top: .5rem;
    margin-bottom: .5rem;
}
.mgl-10{
	margin-left:-10px;
}
.pd10_0{
	padding:10px 0px!important;
}
.m_f100{
	max-width: 100%;
    flex: 0 0 100%;
}
.mgbt0{
	margin-bottom:0px;
}
.reply-List .fa {
    margin-left: 7px;
}
.post-reply-img{
	position: absolute;
    margin-top: 10px;
    margin-top: 3px;
    border: 0;
    color: #fff;
	right: 2px;
}
.post-cancel-img{
	position: absolute;
    margin-top: 10px;
    margin-top: 3px;
    border: 0;
    color: #fff;
	right: 22px;
	right: 37px;
}
.mrgt5{
	margin-top:5px;	
}
.pd5_0 {
    padding: 5px 0px!important;
}
.img-icon-sm30 {
    width: 30px!important;
    height: 30px!important;
    margin-right: 16px;
    flex: none;
    border-radius: 50%;
    background-color: transparent;
    overflow: hidden;
    position: relative;
    margin-top: .5rem;
    margin-bottom: .5rem;
    border: 1px solid #9E9E9E;
}
.pd_t_bt0{
	padding-top:0px!important;
	padding-bottom:0px!important;
}
a.like-bet-active {
   /* background: url(https://www.coopbet.com/media/likedat.png) no-repeat top 2px left 15px;*/
    float: left;
    height: 20px;
    font-size: 13px;
    line-height: 27px;
    text-align: left;
    padding-left: 39px;
    padding-left: 5px;
    cursor: pointer;
    background-size: 19px 19px;	
    margin-top: -4px;
}
a.like-bet-active i{
	font-size: 16px;
    color: #04978b;
}
a.like-bet i{
	font-size: 16px;
	font-size: 20px;
}
.img_soccer{
	width: 20px;
    margin-top: 2px;
	margin-right:10px;
}
.img_baskball{
	width: 24px;
	margin-right:10px;
}
.img_tennis{
	width: 22px;
    margin-top: 1px;
	margin-right:10px;
}
.tool-ball{
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
	padding-left: 0;
    padding-right: 0;
}
.tool-ball.active,.tool-setting.active{
	background-color: #e9ecef;
}
.mrgt7{
	margin-top:7px;
}
.tool-ball-title{
	display: inline;
    vertical-align: bottom;
}
.mgbt3{
	margin-bottom:3px;
	margin-left:0px;
	margin-right:0px;
}
.mgbt7{
	margin-bottom:7px;
	margin-left:0px;
	margin-right:0px;
}
.pdlr0{
	padding-left:0px!important;
	padding-right:0px!important;
}
.icon-tool{
	font-size:20px;
	margin-top: 3px;
    margin-right: 10px;
}
.rigister .fa-check {
    color: #09c710;
    padding-left: 5px;
    padding-right: 10px;
}
.App #rc-anchor-alert, .App .rc-anchor-alert {
    bottom: 0;
    color: red;
    font-size: 13px;
    position: absolute;
    display: none!important;
}
.red-text{
	color:red;
}
.tool-setting {
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    font-weight: 600;
    font-size: 13px;
    padding-left: 0;
    padding-right: 0;
}
.check-edit-pass{
	position: inherit!important;
}
.div-custom-checkbox{
	padding-left:12px!important;
}
.width33{
	width:33%!important;
}
.inl{
	display:inline;
}
.mrgl20{
	margin-left:20px;
}
.mrgl5{
	margin-left:5px;
}
.green-text{
	color:green;
}
.div-custom-checkbox .custom-control-label::before {
    top: 0px;
}
.div-custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    top: 0;
}
.display_block{
	display:block;
}
.add-title{
	padding-top: 5px;
    padding-bottom: 5px;
    text-align: left;
    font-weight: 600;
    font-size: 14px;
}
.add-title.active{
	background-color: #e9ecef;
}
.brth-box {
    border: 1px solid #ccc;
    /*padding-top: 7px;
    width: 49.5%;
    margin-right: .5%;*/
    border-radius: 3px;
    margin-bottom: 10px;
}
.row-ad {
    padding-bottom: 5px;
    border-bottom: 1px solid #ddd;
    margin: 0;
}
.logo-ad {
    width: 100px;
    height: 50px;
	margin-top: 5px;
    -o-object-fit: cover;
    object-fit: cover;
}
.txtcenter {
    text-align: center;
}
.caption-ad {
    font-weight: 700;
    margin-bottom: 0;
    margin-top: 5px;
	font-size: 20px;
}
.lnk-note-ad {
    font-size: 10px;
    color: #ccc;
}
.heig50 {
    height: 50px!important;
}
.creat-by-ad {
    padding: 5px 15px;
    font-size: 11px;
    font-weight: 600;
}
.note-to {
    float: right;
    text-align: right;
    font-size: 11px;
    color: #fd8607;
    padding: 5px 15px;
}
.pdlr5{
	padding-left:5px;
	padding-right:5px;
}
.note-to a{
	text-decoration:underline;
}
.icr{
	float:right;
}
.width100{
	width:100%;
}
.icon-running{
	font-size: 16px;
	font-size: 20px;
    color: #333;
}
.btn-tip{
	background-color: #e5e5e5;
    height: 50px;
    height: auto;
    vertical-align: middle;
    padding: 15px;
    font-size: 14px;
}
.tip-bagr{	
	background-color: #b6b9bd;
	font-weight:600;
}
.btn-num-tip{
	background-color: #cccccc;
    height: 50px;
    height: auto;
    vertical-align: middle;
    padding: 15px;
    font-size: 14px;
	text-align:center;
	font-weight: 600;
}
.mrgLR1{
	margin-left:1px;
	margin-right:1px;
}
.mrgbt5{
	margin-bottom:5px;
}
.left-tip{
	text-align: center;
    border: 0px solid #b6b9bd;
    height: 60px;
    padding: 10px 0px!important;
	border-bottom:0px;
	font-weight:600;
}
.right-tip{
	text-align: center;
    border: 0px solid #b6b9bd;
    height: 60px;
    padding: 10px 0px!important;
	border-left:0px;
	border-bottom:0px;
	font-weight:600;
}
.right-tip div {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  left: 40%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.div-title-tip{
	border: 1px solid #b6b9bd;
    height: 30px;
    background-color: #e5e5e5;
}
.col-title-tip{
  text-align:center;
  padding-top: 5px!important;
  font-weight:600;
}
.fl_l{
	float:left;
}
.fl_r{
	float:right;
}
.div-nm-tip{
   border: 1px solid #b6b9bd; 
   border: 1px solid #dee2e6;
   border-top:0px;
   border-right:0px;
}
.num-tip{
	border-right: 1px solid #b6b9bd; 
	border-right: 1px solid #dee2e6;
	height: 30px;
	padding:5px!important;
}
.title-chose-tip{
	background-color: #e5e5e5;
    height: 50px;
    vertical-align: middle;
    padding: 15px;
    font-size: 14px;
    font-weight: 600;
}
.div-chose{
	font-size:14px;
}
.nameH{
	font-weight:600;    
	text-transform: uppercase;
}
.kcl{
	font-size: 14px;
    font-weight: 600;
    color: #439ca2;
}
.react-numeric-input{
	width:100%
}
.react-numeric-input input{
	width:100%;
	height:35px;
}
.lhei35{
	line-height:35px;
}
.custom-control-label::after{
	top:0px;
}
.custom-checkbox .custom-control-label::before{
	top:0px;
}
.mrgbt0{
	margin-bottom:0px;
}
.custom-control-input:checked~.custom-control-label::before {
    top: 0;
}
.bgct{
	background-color: #e5e5e5;
    padding-top: 10px;
    padding-bottom: 10px;
}
.btn-blue {
    border-radius: 10px;
    display: inline-block;
    padding: 3px 10px;
    background-color: #04978b;
    color: #fff!important;
    width: 70px;
    text-align: center;
}
.dibtn{
	padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid #eee;
}

element.style {
    color: inherit;
}
a {
    color: inherit;
}
.box-content {
    border: 1px solid #eee;
    border-top: none;
    width: 100%;
    float: left;
    background-color: #fff;
}
.create-pick {
    width: 100%;
    float: left;
    text-align: center;
    margin: 15px 0;
}
.icon-sport {
    color: #04978b;
    vertical-align: middle;
    font-size: 26px;
}
.bet-item .match {
    float: left;
    width: 80%;
}
.bet-item .match span {
    display: block;
    padding: 2px;
    float: none;
	font-size:14px;
}
.bet-item span:last-child {
    border: none;
}
.bet-item .match span {
    display: block;
    padding: 2px;
    float: none;
}
.match .bet-type {
    font-weight: 700;
}
.bet-item .amount {
    width: 45px;
    padding-top: 8px;
}
.bet-item .amount {
    text-align: right;
}
.bet-item span {
    display: block;
    padding: 4px 2px;
    float: left;
    border-right: 1px solid transparent;
}
.bet-item span:last-child {
    border: none;
}
.bet-item span {
    display: block;
    padding: 4px 2px;
    float: left;
    border-right: 1px solid transparent;
}
.pdlr10{
	padding-left:10px;
	padding-right:10px;
}
.bet-act{
	float:right;
	font-size:14px;
}
.remove-tip {
    font-size: 16px;
    padding-top: 3px;
    color: red;
    cursor: pointer;
}
.custom-control{
	float:left;
}
.create-pick a.btn {
    cursor: pointer;
    color: #fff;
    text-transform: uppercase;
    font-size: 10px;
    padding: 5px 15px;
    background-color: #04978b;
    border: 1px solid #ddd;
    border-radius: 5px;
}
.checked {
    width: 16px;
    height: 16px;
    margin: 5px;
    background: url(https://www.coopbet.com/media/check_ap.png) no-repeat 0 0;
}
.br-bt {
    border-top: 1px dashed #ddd;
}
#mybet-list-success .bet-item, #mybet-list .bet-item {
    padding: 5px 0px;
}
#tip-item {
   border-bottom: 1px solid #ddd;
    padding: 10px;
}
#mybet-list-success{
	 border-top: 1px solid #ddd;
}
.message-error{
	color:red;
}
.mgbt5{
	margin-bottom:5px;
}
.title-user-info span.ctn {
    background: url(https://www.coopbet.com/media/icontipsterctn.png) no-repeat;
    padding-left: 24px;
}
.pdt34{
	padding-top:34px;
}
.lai{
	padding-top:10px;
	padding-bottom:10px;
}
.friends{
	text-align: center;
    width: 100%;
    padding: 8px 0;
    margin: 9px 0;
}
.add-friend {
    border: 1px solid #ccc;
    border-radius: 5px;
    color: #000;
    margin-right: 5px;
    cursor: pointer;
    position: relative;
	padding: 8px 10px 8px 32px;
    background: #ececec url(https://www.coopbet.com/media/iconaddfriend.png) no-repeat 5px 6px;
}
img.loading {
    position: absolute;
    left: 6px;
    border-radius: 50%;
    top: 8px;
    display: none;
}
.following {
    border: 1px solid #05978b;
    padding: 8px 15px;
    color: #000;
    border-radius: 5px;
    cursor: pointer;
    color: #05978b!important;
}
.add-friended {
    padding: 8px 10px;
    background: #ececec;
	border: 1px solid #ccc;
    border-radius: 5px;
    color: #000;
    margin-right: 5px;
    cursor: pointer;
    position: relative;
}
.following {
    border: 1px solid #05978b;
    padding: 8px 15px;
    color: #000;
    border-radius: 5px;
    cursor: pointer;
    color: #05978b;
}
.flwg {
    background: #04978b;
    border: 1px solid #04978b;
    color: #fff!important;
}
.tool-profile-title{
	display: inline;
    vertical-align: bottom;
	color: #1f595d;
}
.active .tool-profile-title{
	color:#c90a0a;
}
.tool-profile{
	background-color: #e9ecef;
	height: 40px;
    padding-top: 10px;
}
.brdlr{
	border: 1px solid #ccc;
    border-bottom: 0;
    border-top: 0;
}
.mwI{
	max-width:inherit!important;
	padding:0;
}
.rdtPicker .rdtTimeToggle, .rdtPicker td.rdtDay, .rdtPicker td.rdtHour, .rdtPicker td.rdtMinute, .rdtPicker td.rdtSecond,.rdtPicker .rdtTimeToggle:hover, .rdtPicker td.rdtDay:hover, .rdtPicker td.rdtHour:hover, .rdtPicker td.rdtMinute:hover, .rdtPicker td.rdtSecond:hover {
    background: #eee;
    cursor: pointer;
}
.rdtPicker td.rdtNew, .rdtPicker td.rdtOld {
    color: #999;
}
.rdtPicker td {
    cursor: pointer;
}
.rdtPicker td, .rdtPicker th {
    text-align: center;
    height: 28px;
}
.rdtPicker td.rdtActive, .rdtPicker td.rdtActive:hover {
    background-color: #428bca;
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0,0,0,.25);
}
.error3{
	color:red;
}
.num-running{
	padding-left:5px;
}
.row{
	/*width:100%!important;*/
}
.lbl-sex{
	font-size:14px;
	float:left;
}
.needs-validation .row {
    width: inherit!important;
}
.r-btn-s{
	text-align: center;
    display: block;
}
.bet-link:hover {
    background-color: orange;
    color: green;
}
a.bet-link {
    border: 1px solid #e9ecef;
    background: #1f595d0d;
    padding: 3px;
    font-weight: 700;
    cursor: pointer;
}
.name-ad{
	text-align:center;
	font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
}
.img-baner-ad{
	width: 100%;
    /*height: 180px;*/
    overflow: hidden;
    text-align: center;
	padding-bottom:10px;
}
.img-baner-ad img{
	width: 100%;
	width: 340px;
    height: 80px;
    overflow: hidden;
    -o-object-fit: cover;
    object-fit: cover;
}
.mgbtlr{
	margin-bottom:5px;
	margin-top:5px;
	margin-left:16px;
	margin-right:16px;
	width: 100%;
}
.block-title{
	background-color: #e5e5e5;
	padding-top: 8px;
    padding-bottom: 8px;
}
.block-title h4{
	font-size: 16px;
    font-weight: 600;
    margin: 0;
}
.cont-bl{
	margin-bottom: 5px;
    margin-left: 25px;
    margin-right: 25px;
    width: 100%;
	font-size:14px;
}
#profile-block .note {
    font-size: 12px;
    color: #9e9e9e;
}
#profile-block .lb-name {
    font-weight: 600;
    width: 70%;
}
.a-block {
    color: blue!important;
	font-size:14px;
}
#profile-block .mgt20 {
    margin-top: 10px;
}
/*---- format textarea comment---------*/
.wrapper{
    background: #eee;
    background: #f7f6f6fa;
    border: 1px solid #ced4da;
    width: 100%;
    border-radius: .25rem;
    padding: 1px;
	/*padding-right: 5px;*/
}
.wrapper textarea{
    background: linear-gradient(to bottom, #e5e5e5 0%,#f2f2f2 100%);
    background: #f7f6f6fa;
    border:none;
    width:100%;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    /*border-bottom: 1px dotted #999;*/
    resize: none;
	outline: none!important;
	z-index: 1000;
    overflow: hidden;
	
}
.wrapper textarea:focus, .wrapper textarea:active{
    outline: none!important;
	border:0px!important;
	-webkit-appearance:none;
    box-shadow: none !important;
	background: #f7f6f6fa;
}
.controls{
    text-align: right;
    margin-top: -6px;
}
.btn-cmt-ar{
    /*background: linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
    border: 1px solid #999;*/
    padding: 10px 25px;
    padding: 5px;
    /*font-weight: bold;
    color: rgb(77,77,77);*/
    border-width: 1px 0 0 1px;
	background: #04978b;
    border: 0;
    color: #fff;
	border-radius:5px;
	z-index: 10040;
	font-size:12px;
}
.div-block-ct .tfd-delete, .div-block-rl .tfd-delete{
	font-size: 13px;
    font-weight: 600;
    letter-spacing: .007px;
    text-transform: uppercase;
}
.div-block-ct .dr-fr, .div-block-rl .dr-fr {
    border: 1px solid #ccc;
    top: 100%!important;
    float: right;
    right: 35% !important;
    min-width: 110px;
    left: inherit;
}
.div-block-ct .dr-fr a, .div-block-rl .dr-fr a {
    float: left;
    padding: 3px 5px;
    border-radius: 3px;
    opacity: .8;
    cursor: pointer;
    margin: 0 5px;
    position: relative;
    font-size: 13px;
}
.fnt-bold{
	font-weight:bold;
}
.fnt-smal{
	font-size:11px;
	width:30%;
}
.wdt20{
	display:inline-block!important;
	width:20%;
}
.wdt40{
	display:inline-block!important;
	width:40%;
	margin-left:1%;
}
.wdt38{
	width:38%;
	display:inline-block!important;
	margin-left:1%;
}
.wdt80{
	width:80%;
	display:inline-block!important;
}
.wdt61{
	width:61%;
	display:inline-block!important;
}
.bnt-send-code {
    font-size: 14px!important;
    text-transform: inherit!important;
   text-align: center;
}
.btn-sigin {
    border: none!important;
    background-color: #437477!important;
    color: #fff;
}
.btn-sigin:hover {
    background-color: #05978b!important;
    color: #fff;
}
.txt-note{
	font-size: 11px;
    color: #9e9e9e;
	font-style: italic;
}
.v3{
	margin:0px!important;
}
.txt-caz{
	text-transform: capitalize;
}
#btn-create{
	cursor: pointer;
    color: #fff;
    text-transform: uppercase;
    font-size: 10px;
    padding: 5px 15px;
    background-color: #04978b;
    border: 1px solid #ddd;
    border-radius: 5px;
	margin-left:10px;
}
#btn-create:hover, #btn-create:active {
    color: #fff!important;
    background-color: #04978b!important;
    border-color: #ddd!important;
}
.div-load-more{
	text-align: center;
    margin: 5px;
}
#load-more{
	border-radius: 20px;
    background-color: white!important;
    border: 2px solid #008b8b;
    padding: 10px 15px;
    color: darkcyan;
}
#load-more:hover, #load-more:active {
    color: darkcyan!important;
}
.Ripple-parent.doRegister {
	text-transform: uppercase!important;
}
.rw-like {
	padding:10px 30px!important;
}
.save-setting{
	min-width: 120px;
    padding: 10px;
}
.txt-red{
	color:red;
}
.iconmoney{
	margin-right: 5px;
    font-size: 25px;
    vertical-align: text-top;
}
#div-bankroll .nav-tabs {
    border-bottom: 0 solid #ddd;
    background-color: #f6f6f6;
    width: 100%;
}
#div-bankroll .nav-tabs>li {
    float: left;
    margin-bottom: 0;
}
#div-bankroll li>a {
    text-transform: uppercase;
}
#div-bankroll .nav-tabs .nav-link {
    margin-right: 2px;
    line-height: 1.42857143;
    border: 1px solid #f6f6f6;
    border-radius: 0;
    background: #f6f6f6;
    color: #1f595d;
    font-weight: 600;
    border-right: 1px solid #ccc!important;
	text-transform: uppercase;
}
#div-bankroll .nav-tabs .nav-link.active, #div-bankroll .nav-tabs .nav-link:focus, #div-bankroll .nav-tabs .nav-link:hover {
    color: #c90a0a;
    cursor: default;
    background-color: #f6f6f6;
    border: 1px solid #f6f6f6;
    border-bottom-color: transparent;
}
#div-bankroll  .tab-content{
	width:100%;
}
.row-bankboll{
	border:1px solid #ccc;
	margin-top:3px;
	margin-bottom:2px;
}
#div-bankroll .custom-radio{
	padding-top:28px;
	float: left;
}
#div-bankroll .custom-radio .custom-control-input {
    position: initial;
    z-index: 1;
    opacity: 1;
}
.bdl{
	border-left: 1px solid #ccc;

}
._pd0{
	padding:10px 0px;
}
.txtct{
	text-align:center;
}
.txtUpc{
	text-transform:uppercase;
}
.mg1 {
    margin-left: 1px;
    margin-right: 1px;
}
.bckrgrey{
	background-color: #e5e5e5;
}
.bnt-ad-bank{
	    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    color: #fff;
    text-transform: uppercase;
	font-size: 14px!important;
    background-color: #04978b!important;
    padding: 6px 12px!important;
}
#div-bankroll .nav-tabs .nav-link:last-child{
	border-right:0px!important;
}
#div-bankroll .pdlr0{
	padding-left:0px;
	padding-right:0px;
}
#ct-bitcoin .custom-radio {
    padding-top: 8px;
    float: left;
}

.setting .fa-times {
    color: #f55050;
    padding-left: 5px;
    padding-right: 10px;
}
.setting .fa-check {
    color: #09c710;
    padding-left: 5px;
    padding-right: 10px;
}
.lnk-cado{
	text-align: center;
    font-size: 16px;
	line-height: 36px;
	line-height: 25px;
}
.lnk-cado a,.lnk-cado:hover ,.lnk-cado:active,.lnk-cado.focus,
.lnk-cado.active.focus, .lnk-cado.active:focus, .lnk-cado.focus, .lnk-cado:active.focus, .lnk-cado:active:focus, .lnk-cado:focus  {
    color:#1ee64c;
}
.ct-ft-charge{
	height: 28px;
    background-color: #83dbcc;
    border-radius: 4px;
    padding: 4px 1px;
    margin-right: 5px;
    border: 1px solid #3bc5ae;
	text-align: center;
}
.price-charge {
    font-weight: 600;
    color: #333;
    vertical-align: top;
}
.sym-charge {
    color: #fff;
    font-size: 16px;
    vertical-align: top;
    padding-left: 3px;
    padding-right: 3px;
	border-right: 1px solid #fff;
}
.left-ft-admth .fa-shopping-cart, .sym1 {
    color: #fff; padding-left: 3px;font-size: 11px;
}
.triangle-top-left-charge {
    width: 0;
    height: 0;
    border-top: 40px solid red;
    border-right: 50px solid transparent;
    cursor: pointer;
    margin-bottom: -36px;
}
.triangle-text-charge {
    color: #fff;
    font-size: 13px;
    font-weight: 700;
    position: relative;
    right: -2px;
    top: -40px;
    white-space: nowrap;
    transform: rotate(0deg);
}
.date-tips{
	padding:0px!important;text-align:right;padding-right:10px!important;
}
.date-tips input{
	height:28px!important;
}

.date-tips  .react-datepicker-popper{
	 position: absolute;
    will-change: transform;
    top: 0px;
    left: 0;
    transform: translate3d(6px, 28px, 0px)!important;
}
.date-tips .icon-carets {
    position: inherit;
    top: 0px;
    /* left: 73px; */
    /* right: 12px; */
}
.lbl-ck-buy{
	padding-left:10px;
}
.check-buy-tip .custom-control-label::before {
    top: 4px;
	left:-10px;
}
#btn-save-buy-tip {
    width: 100px;
    text-align: center;
    padding: 6px;
    font-size: 15px;
    font-weight: 600;
}
.tx-r-by-tip{
    text-align: right;
	margin-top:10px;
}
#btn-send-iv {
    border-radius: 0;
    line-height: 30px;
}
.iv-ft {
    text-align: center;
    margin-top: 10px;
}
.ic-cpi {
    font-size: 18px;
    margin-right: 10px;
}
.lb14 {
    font-size: 14px;
	font-weight:700;
}
.ic-exa {
    color: #ffab00;
    font-size: 16px;
    margin-right: 5px;
}
.btn-closed {
    color: #333;
    background-color: #fff!important;
    border: 1px solid #ccc!important;
	    width: 100px;
}
.btn-send-i {
    background-color: #9b59b6;
	margin-left:10px;
	width: 100px;
}
.lnk-kito {
    text-align: center;
    font-size: 16px;
    line-height: 36px;
    line-height: 25px;
	border: 1px solid #33333375;
    margin: 1px;
}





/*---- End format textarea comment---------*/

@media (min-width: 570px){
	.fit-bl-2{
		flex: 0 0 15%;
	}
	.fit-bl-3 {
		flex: 0 0 35%;
		max-width: 35%;
	}
	.cm-fit-1{
		max-width: 18%!important;
		flex: 0 0 18%!important;
		max-width: 10%!important;
		flex: 0 0 10%!important;
	}
	.cm-fit-2{
		max-width: 82%!important;
		flex: 0 0 82%!important;
		max-width: 90%!important;
		flex: 0 0 90%!important;
	}
	.fit-rely1{
		max-width: 10%!important;
		flex: 0 0 10%!important;
	}
	.fit-rely2{
		max-width: 90%!important;
		flex: 0 0 90%!important;
	}
	.w20{
		width:30%!important;
	}
	.w80{
		width:70%!important;
	}
	.txt-b { 
		text-align: left;
		margin-left: 15%;
	}
	.check-buy-tip .custom-control-label::before {
		left:-1.5rem;
	}
	.lbl-ck-buy{
		padding-left:0px;
	}
}

@media (min-width: 760px){
	.fit-bl-2 {
		flex: 0 0 10.666667%;
	}
	.fit-bl-3{
		flex: 0 0 31%;
		max-width: 31%;
	}
	.cm-fit-1{
		max-width: 10%!important;
		flex: 0 0 10%!important;
	}
	.cm-fit-2{
		max-width: 90%!important;
		flex: 0 0 90%!important;
	}
	.area-cmt{
		/*width:93%;*/
	}
	.div-block-ct .dr-fr, .div-block-rl .dr-fr {
		border: 1px solid #ccc;
		top: 100%!important;
		float: left;
		/* right: 35% !important; */
		min-width: 110px;
		left: 20%;
		right: inherit!important;
	}
	.dr-fr:before{
		left: 15px;
		right: inherit!important;
	}
}
